



.table-edit-cell-dropdown {
  display: flex;
  position: absolute;
  margin: 0px;
  z-index: 105;
}

.ReactModal__Overlay {
  z-index: 10000;
  background-color: #0000007f !important;
}

.ReactModal__Content.admin-large-modal {
  border: 0 !important;
  width: 88% !important;
  height: auto !important;
  padding: 0 !important;
  outline: none !important;
  left: auto !important;
  top: 6% !important;
  position: relative;
  margin: 0 auto;
  background: white;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .ReactModal__Content.admin-large-modal {
    width: 90% !important;
  }
}

.ReactModal__Content.admin-medium-modal {
    border: 0 !important;
    width: 50% !important;
    height: auto !important;
    padding: 0 !important;
    outline: none !important;
    left: auto !important;
    top: 7% !important;
    position: relative;
    margin: 0 auto;
    background: white;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .ReactModal__Content.admin-medium-modal {
      width: 90% !important;
    }
  }

  .ReactModal__Content.admin-small-modal {
    border: 0 !important;
    width: 35% !important;
    height: auto !important;
    padding: 0 !important;
    outline: none !important;
    left: auto !important;
    top: 7% !important;
    position: relative;
    margin: 0 auto;
    background: white;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .ReactModal__Content.admin-small-modal {
      width: 90% !important;
    }
  }
  .admin-modal-area{
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
  }
  .admin-modal-area .admin-modal-header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f4;
    padding-bottom: 10px;
  }

  .admin-modal-area .admin-modal-footer{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #f1f1f4;
    padding-top: 10px;
  }

  form .form-control.is-invalid{
    border-color: var(--bs-form-invalid-border-color) !important;
  }
  form .form-select.is-invalid{
    border-color: var(--bs-form-invalid-border-color) !important;
  }
  form .form-select.is-valid{
    border-color: var(--bs-form-valid-border-color) !important;;
  }
  form .form-control.is-valid{
    border-color: var(--bs-form-valid-border-color) !important;;
  }

  .common-delete-alert {
    display: flex;
    align-items: center;
    justify-content: start;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #ff7b52;
    height: 50px;
    padding: 15px 10px 15px 0px;
    gap: 10px;
  }

  .common-delete-alert-icon {
    background: #ff7b52;
    height: 49px;
    width: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .produc-images-area {
      min-height: 170px;
      padding: 5px;
      border: 2px solid #99A1B7;
      margin-top: 20px;
      border-radius: 5px;
  }

  .produc-images-inner {
    outline: 2px dashed #99A1B7;
    min-height: 165px;
    margin: 3px;
    border-radius: 4px;
    gap: 5px;
    align-items: center;
    justify-content: center;
    justify-content: start;
    padding: 10px;
}
.produc-images-area .img-button-remove{
  position: absolute;
  top: 0px;
  right: 1px;
  width: 17px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  border-radius: 3px;
}
.produc-images-inner img{
border-radius: 4px;
width: 100%;
height: 150px;
}
.admin-modal-height{
  max-height: 500px;
  overflow: auto;
}

.Toastify__toast-container {
  z-index: 200000 !important;
}

.border-none{
  border: none !important;
}

.custom-modal-height{
  max-height: 500px;
  overflow: auto;
}

.tax-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
}

.tax-container select, 
.tax-container input {
  border: none;
  outline: none;
  padding: 8px;
}

.tax-container select {
  border-right: 1px solid #ccc;
}

.tax-container input {
  flex-grow: 1;
}

.order-tax-box{
  border: 1px solid #3c2e2e;
    padding: 7px;
}

.order-unit-field{
  border-radius: 4px;
    border: none;
    outline: none;
    width: 100px;
    padding: 5px;
}

.jobcard-td{
    gap:10px;
}

.jobcard-td a{
  border: 1px solid #e9f3ff;
  border-radius: 2px;
  padding: 4px;
 
}


body #root .card-body{
  & .table {
   & thead {
     & tr{
      &  th{
        font-size: 16px !important;
        font-weight: bold !important;
        color: #000 !important;

        }
      }
   }
     & tbody{
        & tr {
          border-bottom: 1px solid #ccc !important;
          td *, td{
            font-size: 14px !important; 
            margin-left: 0 !important;
            color: #000 !important;
          }
          
        }
      }
      & tfoot{
        & tr {
        
          td *, td{
            font-size: 14px !important; 
            margin-left: 0 !important;
            color: #000 !important;
            border-bottom: 1px solid #cccccc8a;
          }
          
        }
      }
  }
}
.app-sidebar-footer{
 & .btn-label{
  color: #ffffff !important;
  font-size: 16px;
  font-weight: bold
  }
}

.pagination{
  margin-top: 5px !important;
}

.badge{
  font-size:  1rem !important;
}

body #root .btn.btn-light-primary.me-3{
background: #ccc !important;
color: #000 !important;
& i {
  display:  none !important;
}
}
body #root .btn.btn-primary{
  background:  #999999 !important;
}


body #root  .page-item.active .page-link{
  background:  #999999 !important;
}
.badge-light{
  background:none !important ;
}

.container-fluid{
 & .btn.btn-sm.fw-bold.btn-danger{
    font-size: 16px !important;
    background:  #999999 !important;
  }
}

.admin-modal-footer{
 & .btn.btn-danger{
font-size: 16px !important;
    background:  #999999 !important;
    &:hover{
      background:  #666 !important;
    }
  }
  
}


.card-body.container-xxl .mb-lg-0{
  width: 100%;
}
.tab-content .bg-light-warning{
  background-color:#cccccc47 !important;
}
/* .path2, .path1,  */
 .text-warning.me-5{
  display: none;
}
.text-end .ki-duotone{
  display: none;
}
.btn.btn-warning{
  background: #666 !important;
} 

.bgi-size-cover.mt-3.bgclor{
  background-color:#cccccc47 !important;
  & .card-body {
    padding: 0rem;
    &.container-xxl{
      max-width: 100%;
            padding: 2rem;
    }
  }
}

.loginlogo img{
width: 200px;
}
.loginscreen{
  background: #ccc;
}
input.form-select{
  padding: 0.775rem 1rem 0.775rem 1rem;
} 
/* 
.table-responsive .table-row-dashed tbody tr:nth-of-type(even){
  background: #f5f5f5;
 
} */
.table-responsive .table-row-dashed tbody tr{
  border-bottom:1px solid #ccc !important;
}
input.order-unit-field{
  padding: 0;
}
.card-body .table-responsive .mb-2{
  margin-bottom: 0px;
}
.table-responsive tr th.min-w-100px:first-child{
  min-width:150px !important;
}

.printview_jobcard{
  & .col-lg-4{
    width: 25%; 
  }
}
.width-25{
  width: 25% !important;
}
.width-75{
  width: 75% !important;
}
.machine-based-report .dataTable.no-footer tr.text-start th.min-w-125px{
  min-width: 100px !important;
}
@media print {
  .printview_jobcard{
    & .col-lg-4{
      width: 33.33%;
     
      &.last_pane{ width: 100%;
        grid-template-columns: 1fr 1fr !important; 
        gap: 1px 1px !important;
       
        
      }
    }
  }
  .card.printable-area .col-lg-4{width: 40%; float: left;}
  .machine-based-report .dataTable.no-footer tr.text-start th.min-w-125px{
    min-width: 80px !important;
  }
}



.print-form-label{
  font-weight: bold;
}

.print-form-value {
  border-bottom: 1px solid #000;
  padding: 5px 0;
}

.print-form-section-urdu {
  display: grid;
  grid-template-columns: 1fr 4fr; /* Adjust column ratio */
  gap: 3px 3px;
  align-items: center;
}
.print-urdu-label{
  font-size: 18px !important;
}

.print-job-card-empty-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.print-job-card-empty-table th, .print-job-card-empty-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  height: 40px;
}

.print-job-card-empty-table th {
  background-color: #f2f2f2;
}

.text-mid {
  text-align: center;
}


/* .app-header-menu .menu .menu-item .menu-link.active .menu-title{

} */